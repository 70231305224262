#features {
  width: 60%;
  margin: 15rem auto;
}

#features > h1 {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#features > h1 > span {
  font-size: 2rem;
  font-weight: bold;
  color: var(--secondary);
  text-align: center;
}

#features > article {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
  gap: 3rem;
  margin-top: 5rem;
}

#features > article > div {
  opacity: 0;
}

#features_visible > div:nth-child(1) {
  animation-name: feature;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#features_visible > div:nth-child(2) {
  animation-name: feature;
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#features_visible > div:nth-child(3) {
  animation-name: feature;
  animation-delay: 0.4s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#features_visible > div:nth-child(4) {
  animation-name: feature;
  animation-delay: 0.6s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#features_visible > div:nth-child(5) {
  animation-name: feature;
  animation-delay: 0.8s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#features_visible > div:nth-child(6) {
  animation-name: feature;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes feature {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1) !important;
  }
}

.features_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  box-shadow: 0px 0px 10px var(--third);
  width: 100%;
  background-color: var(--third);
  border-radius: 10px;
}

.features_wrapper > svg {
  fill: var(--secondary);
  width: 35px;
}

.features_wrapper > h4 {
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.features_wrapper > p {
  color: #cccc;
  font-size: 1.2rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-weight: normal;
}

@media screen and (max-width: 1800px) {
  #features {
    width: 70%;
  }
}

@media screen and (max-width: 1550px) {
  #features {
    width: 75%;
  }
}

@media screen and (max-width: 900px) {
  #features {
    width: 85%;
  }
}

@media screen and (max-width: 450px) {
  #features {
    width: 90%;
  }
  .features_wrapper {
    padding: 1.5rem;
  }
  .features_wrapper > h4 {
    font-size: 1.2rem;
  }
  .features_wrapper > p {
    font-size: 1rem;
  }
  #features > article {
    grid-template-columns: 1fr;
  }
  #features {
    margin-top: 5rem;
  }
}
