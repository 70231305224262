* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary: #0d0e10;
  --secondary: #2ed573;
  --third: #191b1f;
}

body {
  background-color: #0d0e10;
  font-family: "Roboto", sans-serif;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: var(--secondary);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
