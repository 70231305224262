#Transaction {
  width: 60%;
  margin: 4rem auto;
  display: flex;
  justify-content: space-between;
}

#Transaction aside {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#Transaction aside:first-child {
  gap: 2rem;
}

#Transaction aside:first-child > svg {
  width: 45px;
  fill: var(--secondary);
  animation-name: transOne;
  animation-fill-mode: forwards;
  animation-delay: 0.14s;
  animation-duration: 1s;
  transform: scale(0.8);
  opacity: 0;
}

#Transaction aside:nth-child(2) {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

#Transaction aside:nth-child(2) > svg {
  width: 25px;
  fill: lightgrey;
}

#Transaction aside:nth-child(3) {
  width: 25rem;
}

#Transaction aside:last-child {
  justify-content: space-between;
}

.transaction_input {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: var(--third);
  border-radius: 20px;
  width: 25rem;
}

.transaction_input:first-child {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}

.transaction_input:last-child {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}

.transaction_status_wrapper:nth-of-type(1) {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}
.transaction_status_wrapper:nth-of-type(2) {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}
.transaction_status_wrapper:nth-of-type(3) {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.4s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}
.transaction_status_wrapper:nth-of-type(4) {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}

#Transaction aside:nth-child(2) > svg:nth-of-type(1) {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}

#Transaction aside:nth-child(2) > svg:nth-of-type(2) {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}

#Transaction aside:nth-child(2) > svg:nth-of-type(3) {
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.4s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}

@keyframes transOne {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.transaction_input > h2 {
  user-select: none;
  color: var(--secondary);
  font-size: 1.8rem;
  font-weight: bold;
}

.transaction_input > span {
  font-weight: bold;
  color: #cccc;
  font-size: 1.1rem;
  display: flex;
  gap: 0.4rem;
  margin-top: 1rem;
}

.transaction_input > span:last-child {
  flex-direction: column;
}

.transaction_input > span > h6 {
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  word-break: break-all;
}

.transaction_status_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.transaction_status_wrapper > div {
  padding: 1rem;
  width: fit-content;
  border-radius: 10px;
  background-color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
}

.transaction_status_wrapper > div > svg {
  width: 35px;
  fill: var(--primary);
}

.transaction_status_wrapper > span {
  color: lightgrey;
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
}

#transaction_details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: var(--third);
  border-radius: 20px;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}

#transaction_details > h5 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #cccc;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  user-select: none;
}

#transaction_details > h5 > h6 {
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
}

#transaction_details > h5 > h6 > span {
  color: var(--secondary);
}

#transaction_webhook {
  width: 100%;
  padding: 2rem;
  border-radius: 20px;
  background-color: var(--third);
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-name: transOne;
  transform: scale(0.8);
  opacity: 0;
}

#transaction_webhook > h2 {
  font-weight: bold;
  color: #cccc;
  user-select: none;
  font-size: 1.5rem;
}

#transaction_webhook_input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

#transaction_webhook_input_wrapper > input {
  background-color: transparent;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  border: none;
  margin-top: 1.5rem;
}

#transaction_webhook_input_wrapper > input::placeholder {
  color: lightgrey;
}

#transaction_webhook_input_wrapper > input:focus {
  outline: none;
}

#transaction_webhook_input_wrapper > div {
  width: 0%;
  height: 0.3rem;
  background-color: var(--secondary);
  border-radius: 100px;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-name: inputAnim;
}

@keyframes inputAnim {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

#transaction_webhook > button {
  border: none;
  border-radius: 3px;
  background-color: var(--secondary);
  color: var(--primary);
  transition: 0.2s;
  padding: 0.5rem 1.3rem;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

#transaction_webhook > button:hover {
  transform: translateY(-0.1rem);
}

.completed > div {
  background-color: var(--secondary);
}

.completed > span {
  color: var(--secondary);
}

@media screen and (max-width: 1800px) {
  #Transaction {
    width: 70%;
  }
}

@media screen and (max-width: 1550px) {
  #Transaction {
    width: 75%;
  }
}

@media screen and (max-width: 1450px) {
  #Transaction {
    flex-direction: column;
    gap: 3rem;
  }
  #Transaction aside:nth-child(3) {
    width: 100%;
  }
  #Transaction > aside {
    flex-direction: row;
    justify-content: space-between;
  }
  #Transaction > aside:nth-of-type(3) {
    gap: 2rem;
    flex-direction: column !important;
  }
  #Transaction aside:first-child > svg {
    transform: rotate(270deg) !important;
  }
  #Transaction aside:nth-child(2) > svg {
    width: 35px;
    margin-top: 1.4rem;
    align-self: flex-start;
  }
  .transaction_status_wrapper > span {
    font-size: 1.3rem;
  }
  .transaction_status_wrapper > div {
    padding: 1.5rem;
  }
  .transaction_input {
    width: 34vw !important;
  }
  .transaction_input > span {
    word-break: break-all;
  }
  #Transaction aside:nth-child(2) > svg {
    transform: rotate(270deg) !important;
  }
  #Transaction aside:last-child {
    flex-direction: column;
    gap: 1.4rem;
  }
  #transaction_details {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .transaction_status_wrapper > span {
    max-width: 8rem;
    text-align: center;
  }
  .transaction_status_wrapper {
    align-items: center;
  }
  #Transaction aside:nth-child(2) {
    align-items: stretch;
  }
}

@media screen and (max-width: 1100px) {
  #Transaction {
    width: 85%;
  }
}

@media screen and (max-width: 1000px) {
  #transaction_details {
    flex-direction: column;
  }
  .transaction_input {
    width: 40vw;
  }
}

@media screen and (max-width: 800px) {
  #Transaction aside:first-child {
    flex-direction: column;
  }
  .transaction_input {
    width: 100% !important;
  }
  #Transaction aside:first-child > svg {
    transform: rotate(0) !important;
  }
  .transaction_status_wrapper > div {
    padding: 1rem;
  }
  #Transaction aside:nth-child(2) > svg {
    width: 30px;
  }
  .transaction_status_wrapper > span {
    font-size: 1.1rem;
    max-width: 6rem;
  }
}

@media screen and (max-width: 600px) {
  #Transaction aside:nth-child(2) > svg {
    display: none;
  }
  #transaction_details > h5 > h6 {
    word-wrap: break-word;
  }
}

@media screen and (max-width: 450px) {
  #Transaction aside:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .transaction_status_wrapper > div {
    padding: 1.5rem;
  }
  .transaction_status_wrapper > div > svg {
    width: 40px;
  }
  .transaction_status_wrapper > span {
    font-size: 1.3rem;
    max-width: none;
  }
  #Transaction {
    width: 90%;
  }
  .transaction_input {
    padding: 1.5rem;
  }
  #transaction_details {
    padding: 1.5rem;
  }
  #transaction_webhook {
    padding: 1.5rem;
  }
}
