#FAQ {
  width: 60%;
  margin: 10rem auto;
}

#FAQ > h1 {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#FAQ > h1 > span {
  font-size: 2rem;
  font-weight: bold;
  color: var(--secondary);
  text-align: center;
}

#FAQ > article {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 5rem;
}

#FAQ > article > div {
  opacity: 0;
  transform: scale(0.8);
}

.faq_visible div:nth-child(1) {
  animation-name: faq;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}
.faq_visible div:nth-child(2) {
  animation-name: faq;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
.faq_visible div:nth-child(3) {
  animation-name: faq;
  animation-duration: 1s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}
.faq_visible div:nth-child(4) {
  animation-name: faq;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

@keyframes faq {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 1800px) {
  #FAQ {
    width: 70%;
  }
}

@media screen and (max-width: 1550px) {
  #FAQ {
    width: 75%;
  }
}

@media screen and (max-width: 900px) {
  #FAQ {
    width: 85%;
  }
  .FAQDrop {
    max-height: 8rem !important;
  }
  .FAQCard_header > h2 {
    font-size: 1.2rem;
  }
  .FAQCard_wrapper > p {
    font-size: 1.1 rem;
  }
}

@media screen and (max-width: 450px) {
  #FAQ {
    width: 90%;
  }

  .FAQCard_wrapper {
    padding: 1.3rem;
  }
  .FAQCard_header > h2 {
    width: 15rem;
  }
}
