#exchange {
  width: 60%;
  margin: 8rem auto;
  transform: scale(0.8);
  transition: 1s;
}

.exchange_visible {
  transform: scale(1) !important;
}

#exchange > h1 {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-align: center;
  user-select: none;
}

.swap_search {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: sticky;
  top: 0;
  background-color: #191a1e;
  border-bottom: 2px solid var(--secondary);
}

.swap_search svg {
  min-width: 22px;
  height: 22px;
  fill: var(--secondary);
}

.swap_search input {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.swap_search input::placeholder {
  color: lightgrey;
}

.swap_search input:focus {
  outline: none;
}
#exchange > h1 > span {
  font-size: 2rem;
  font-weight: bold;
  color: var(--secondary);
}

#exchange > article {
  background-color: var(--third);
  border-radius: 14px;
  padding: 3.4rem;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#exchange_conversation_wrapper {
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.prohibited_coin {
  background-color: red !important;
  cursor: not-allowed !important;
}

#exchange_conversation_wrapper > fieldset {
  border: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.exchange_input_wrapper {
  display: flex;
  border-radius: 8px;
  position: relative;
  width: fit-content;
}

.exchange_input_wrapper > input {
  border: 2px solid var(--secondary);
  padding: 0.7rem 0;
  font-weight: bold;
  color: white;
  text-align: center;
  background-color: transparent;
  font-size: 1.1rem;
  box-sizing: border-box;
  height: 100% !important;
}

.exchange_input_wrapper > input:first-child {
  border-radius: 8px 0 0 8px;
}

.exchange_input_wrapper > input:nth-child(2) {
  border-radius: 0px 8px 8px 0px;
}

.exchange_input_wrapper > input:focus {
  outline: none;
}

.exchange_input_wrapper > button {
  padding: 0.5rem 1.2rem;
  background-color: var(--secondary);
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  gap: 0.8rem;
  height: auto;
}

.exchange_input_wrapper > button:nth-child(1) {
  border-radius: 8px 0 0 8px;
}

.exchange_input_wrapper > button:nth-child(2) {
  border-radius: 0 8px 8px 0;
}

.exchange_input_wrapper > button > span {
  font-size: 1.1rem;
  font-weight: bolder;
  color: var(--primary);
}

.exchange_input_wrapper > button > svg {
  fill: var(--primary);
  transition: 0.2s;
}

.exchange_dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--secondary);
  border-radius: 0 0 10px 10px;
  z-index: 30;
  transform: translateY(1rem);
  opacity: 0;
  visibility: hidden;
  width: 100%;
  transition: 0.2s;
}

#triggeredExchangeDropdown {
  transform: translateY(0rem);
  opacity: 1;
  visibility: visible;
}

#triggeredExchangeDropdownTwo {
  transform: translateY(0rem);
  opacity: 1;
  visibility: visible;
}

.exchange_dropdown > ul {
  padding: 0;
  margin: 0;
  max-height: 16rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.exchange_dropdown > ul::-webkit-scrollbar {
  width: 7px;
}

.exchange_dropdown > ul::-webkit-scrollbar-track {
  background-color: transparent;
}

.exchange_dropdown > ul::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 100px;
}

.exchange_dropdown > ul > li {
  list-style-type: none;
  color: var(--primary);
  font-size: 1.1rem;
  font-weight: bolder;
  padding: 0.5rem 1.8rem;
  cursor: pointer;
}

.exchange_dropdown > ul > li:hover {
  background-color: lightgray;
}

#exchange_conversation_wrapper > fieldset > h6 {
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

#exchange_conversation_wrapper > fieldset > h6 > span {
  color: var(--secondary);
  font-size: 1rem;
  font-weight: bold;
}

#exchange_loader {
  display: flex;
  gap: 3rem;
  align-items: center;
  margin-bottom: 2rem;
}

.rotateSVG > svg {
  animation-name: rotateExchange;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rotateSVG > div {
  animation-name: scaleExchange;
  animation-duration: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateExchange {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scaleExchange {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.4);
  }
}

#exchange_loader > div {
  width: 1.1rem;
  height: 1.1rem;
  background-color: white;
  border-radius: 100px;
}

#exchange_loader > svg {
  fill: var(--secondary);
  width: 50px;
  transition: 0;
}

#excahnge_address {
  position: relative;
  width: 22%;
  margin: 3.4rem 0;
  transition: 1s;
}

.address_visible {
  width: 70% !important;
}

#excahnge_address > input {
  border: none;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: transparent;
  color: white;
  margin-bottom: 0.7rem;
}

#excahnge_address > input::placeholder {
  color: lightgray;
}

#excahnge_address > input:focus {
  outline: none;
}

#excahnge_address > div {
  display: block;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: var(--secondary);
  height: 0.4rem;
  border-radius: 100px;
}

#exchange > article > button {
  padding: 0.8rem 2.4rem;
  background-color: var(--secondary);
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  font-weight: bolder;
  font-size: 1.1rem;
  transition: 0.2s ease-in-out;
}

#exchange > article > button:hover {
  transform: translateY(-0.2rem);
}

.dropBtn {
  border-radius: 0 8px 0 0 !important;
}

.dropBtn > svg {
  transform: rotate(180deg);
}

.dropBtnTwo {
  border-radius: 8px 0 0 0 !important;
}

.dropBtnTwo > svg {
  transform: rotate(180deg);
}

@media screen and (max-width: 1800px) {
  #exchange {
    width: 70%;
  }
}

@media screen and (max-width: 1550px) {
  #exchange {
    width: 75%;
  }
}

@media screen and (max-width: 1450px) {
  #exchange_conversation_wrapper {
    flex-direction: column;
  }

  #exchange_conversation_wrapper > fieldset:last-child {
    align-self: flex-end;
  }
  #exchange_loader {
    align-self: center;
  }
}

@media screen and (max-width: 1000px) {
  #exchange_conversation_wrapper {
    gap: 2rem;
  }
  #exchange_loader {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 900px) {
  #exchange {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .exchange_input_wrapper > input {
    width: 100%;
  }
  .exchange_input_wrapper {
    width: 100%;
  }
  #exchange_conversation_wrapper > fieldset:last-child {
    width: 100%;
  }
  #exchange > article {
    padding: 2.4rem;
  }
  #excahnge_address {
    margin: 2.4rem;
  }
}

@media screen and (max-width: 500px) {
  #excahnge_address {
    width: 90%;
  }
  .address_visible {
    width: 90% !important;
  }

  #exchange {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 450px) {
  #exchange {
    width: 90%;
  }
  #exchange > article {
    padding: 1.5rem;
  }
  #exchange_conversation_wrapper {
    gap: 3rem;
  }
}

@media screen and (max-width: 380px) {
  #exchange > article {
    padding: 1.5rem 0.4rem;
  }
}
