.taxModalParent {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  background-color: hsla(0, 100%, 0%, 20%);
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
}

.taxModalParent > div {
  background-color: black;
  width: 25rem;
  height: 13rem;
  border-radius: 5px;
  box-shadow: 0px 0px 30px var(--secondary);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  visibility: hidden;
  transform: translateY(30%);
  transition: 0.2s;
}

.taxModalParent > div > h1 {
  font-size: 1.8rem;
  color: red;
}

.taxModalParent > div > p {
  color: white;
}

.taxModalParent > div > button {
  padding: 0.5rem 1.1rem;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 1.1rem;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.4s;
}

.taxModalParent > div > button:hover {
  transform: translateY(-0.2rem);
}

#triggerModal > div {
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(-10%) !important;
}

#triggerModal {
  visibility: visible !important;
  opacity: 1 !important;
}
