nav {
  padding: 2rem 0;
  display: flex;
  width: 60%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  position: relative;
  position: sticky;
  top: 0;
  background-color: var(--primary);
  z-index: 10;
}

#logo svg {
  width: 18rem;
  height: fit-content;
}

nav::after {
  content: "";
  display: block;
  height: 0.2rem;
  background-color: var(--secondary);
  position: absolute;
  left: 40%;
  width: 20%;
  top: 100%;
  transition: 0.4s;
  opacity: 0;
}

#scrollNav::after {
  left: 0% !important;
  width: 100%;
  opacity: 1 !important;
}

#logo {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  user-select: none;
  cursor: pointer;
}



#logo span {
  color: var(--secondary);
  font-weight: bold;
  font-size: 2rem;
}

#nav_links {
  display: flex;
}

#nav_links > ul {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 5rem;
  align-items: center;
}

#nav_links > ul > li {
  list-style-type: none;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  position: relative;
  padding: 0.2rem 0;
  cursor: pointer;
  user-select: none;
}

#nav_links > ul > li::after {
  content: "";
  position: absolute;
  display: block;
  top: 100%;
  width: 70%;
  height: 0.2rem;
  left: 0;
  background-color: var(--secondary);
  border-radius: 100px;
  transition: 0.2s ease-in-out;
}

#nav_links > ul > li:hover:after {
  width: 100%;
}

#nav_menu {
  fill: var(--secondary);
  width: 35px;
  display: none;
}

#nav_social_wrapper {
  display: flex;
  gap: 1rem;
}

#nav_social_wrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#nav_social_wrapper > div > svg {
  width: 35px;
  height: 35px;
  fill: var(--secondary);
}

@media screen and (max-width: 1800px) {
  nav {
    width: 70%;
  }
}

@media screen and (max-width: 1550px) {
  nav {
    width: 75%;
  }
}

@media screen and (max-width: 1100px) {
  nav {
    width: 85%;
    padding: 2rem 5%;
  }
}

@media screen and (max-width: 1000px) {
  nav {
    width: 85%;
    padding: 2rem 0;
  }
  #nav_links > ul {
    gap: 2.5rem;
  }
}
@media screen and (max-width: 800px) {
  #logo {
    font-size: 1.5rem;
  }
  #logo > span {
    font-size: 1.5rem;
  }
  #nav_links > ul {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    gap: 0;
    flex-direction: column;
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
  }

  #resNavDrop {
    opacity: 1 !important;
    visibility: visible !important;
  }

  #nav_links > ul > li {
    width: 100%;
    text-align: center;
    background-color: var(--secondary);
    padding: 1.5rem 0;
    font-size: 1.2rem;
    color: var(--primary);
  }

  #nav_links > ul > li::after {
    display: none;
  }
  #nav_menu {
    display: block;
  }
  #nav_social_wrapper {
    background-color: var(--secondary);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1.5rem 0;
  }
  #nav_social_wrapper > div > svg {
    fill: var(--primary);
  }
  #logo svg {
    width: 15rem;
  }
}

@media screen and (max-width: 450px) {
  nav {
    width: 90%;
  }
  #logo svg {
    width: 13rem;
  }
}
