.FAQCard_wrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--secondary);
  border-radius: 15px;
  background-color: var(--third);
  padding: 2rem;
  cursor: pointer;
  position: relative;
}

.FAQCard_wrapper::after {
  border-radius: 15px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px var(--secondary);
  opacity: 0;
  z-index: -1;
  transition: 0.2s ease-in-out;
}

.FAQCard_wrapper:hover:after {
  opacity: 1;
}

.FAQCard_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FAQCard_header > h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.FAQCard_wrapper > p {
  color: #cccc;
  font-size: 1rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 1.2rem;
  max-height: 0px;
  margin-top: 0rem;
  opacity: 0;
  transition: 0.2s;
}

.FAQDrop {
  max-height: 3em !important;
  margin-top: 1rem !important;
  opacity: 1 !important;
}

.FAQCard_wrapper > p > a {
  color: var(--secondary);
  font-weight: bold;
  text-decoration: none;
}

.FAQCard_header > div {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 8px;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.FAQSVG > svg {
  transform: rotate(180deg) !important;
}

.FAQCard_header > div > svg {
  width: 18px;
  height: 18px;
  fill: var(--primary);
  transition: 0.2s;
}
