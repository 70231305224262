footer {
  width: 100%;
  background-color: var(--third);
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer > section {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer > section > aside:nth-child(1) {
  display: flex;
  flex-direction: column;
  width: 20rem;
}

#footer_logo > a > svg {
  width: 15rem;
  height: fit-content;
}

footer > section > aside:nth-child(1) > h1 {
  font-size: 2rem;
  cursor: pointer;
  font-weight: bold;
  color: white;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

footer > section > aside:nth-child(1) > h1 span {
  font-size: 2rem;
  font-weight: bold;
  color: var(--secondary);
  text-align: center;
}

footer > section > aside:nth-child(1) > h6 {
  color: #cccc;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 1rem;
  user-select: none;
}

footer > section > aside:nth-child(2) {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8rem;
}

footer > section > aside:nth-child(2) > div {
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer > section > aside:nth-child(2) > div:last-child {
  gap: 1rem;
}

footer > section > aside:nth-child(2) > div > span {
  color: white;
  font-weight: bold;
  user-select: none;
  font-size: 1.3rem;
  cursor: pointer;
}
footer > section > aside:nth-child(2) > div svg {
  width: 40px;
  cursor: pointer;
  fill: var(--secondary);
}

@media screen and (max-width: 1800px) {
  footer > section {
    width: 70%;
  }
}

@media screen and (max-width: 1550px) {
  footer > section {
    width: 75%;
  }
}

@media screen and (max-width: 1200px) {
  footer > section > aside:nth-child(2) {
    gap: 5rem;
  }
}

@media screen and (max-width: 1000px) {
  footer > section > aside:nth-child(2) {
    gap: 2rem;
  }
}

@media screen and (max-width: 900px) {
  footer > section {
    width: 85%;
  }
}

@media screen and (max-width: 750px) {
  footer > section > aside:nth-child(1) {
    width: 15rem;
  }
}

@media screen and (max-width: 680px) {
  footer > section {
    flex-direction: column;
    gap: 3rem;
  }
  footer > section > aside:nth-child(1) {
    width: auto;
  }
  footer > section > aside:nth-child(2) {
    width: 100%;
  }
  footer > section > aside:nth-child(2) > div {
    flex-direction: row;
  }
  footer > section > aside:nth-child(2) > div {
    justify-content: initial;
  }
  footer > section > aside:nth-child(2) > div:last-child {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 450px) {
  footer > section > aside:nth-child(2) {
    flex-direction: column;
    display: flex;
  }
  footer > section > aside:nth-child(2) > div:last-child {
    justify-content: initial;
  }
}
