#TOS {
  width: 60%;
  margin: 2rem auto;
}

#TOS h1 {
  color: var(--secondary);
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

#TOS p {
  color: #cccc;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

#TOS span {
  color: white;
}

#TOS h4 {
  font-size: 1.3rem;
  color: white;
  margin: 1rem 0;
}

#TOS h3 {
  color: var(--secondary);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 2rem 0;
  margin-bottom: 1rem;
}

#TOS ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#TOS ul li {
  color: white;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

#TOS ul li::before {
  content: "";
  width: 0.7rem;
  display: inline-block;
  border-radius: 100%;
  background-color: var(--secondary);
  height: 0.7rem;
  margin-right: 0.4rem;
}

@media screen and (max-width: 1800px) {
  #TOS {
    width: 70%;
  }
}

@media screen and (max-width: 1550px) {
  #TOS {
    width: 75%;
  }
}

@media screen and (max-width: 1000px) {
  #TOS {
    width: 85%;
  }
}
